<template>
  <BasePage
    :bannerUrl="BiddingbannerImg"
    subTilte="文安县城发集团欢迎您"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
    :hideLeft="true"
  >
    <LiAction />
    <SearchFormType />
    <SearchFormSort />
    <!-- <BiddingTable /> -->
    <!-- <PurchasingTable /> -->
    <newPurchasingTable />
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import BasePage from "@/components/BasePage/BasePage";
import LiAction from "./components/LiAction/LiAction";
import SearchFormType from "./components/SearchFormType/SearchFormType";
import SearchFormSort from "./components/SearchFormSort/SearchFormSort";
// import BiddingTable from "./components/BiddingTable/BiddingTable";
// import PurchasingTable from "./components/PurchasingTable/PurchasingTable";
import newPurchasingTable from "./components/newPurchasingTable/newPurchasingTable";
export default {
  components: {
    BasePage,
    LiAction,
    SearchFormType,
    SearchFormSort,
    // BiddingTable,
    // PurchasingTable,
    newPurchasingTable,
  },
  setup() {
    const data = reactive({});

    return {
      ...data,
      ...imgData,
    };
  },
};
</script>

<style scoped>
</style>

